<template>
	<PopupNotification ref="notification"/>
	<button class="orange_button update_button" :disabled="loading" @click="updatePreview">
		<img src="@/assets/images/sync.svg" alt="Button icon" class="button-icon">
		<span>Mettre à jour</span> 
	</button>
	<!-- visualisation docx -->
	<!-- <div v-if="docxContent" v-html="docxContent"></div> -->
	<!-- <VueDocPreview :value="this.file_url" type="office" /> -->

	<!-- visualisation pdf (marche mais la conversion docx > pdf ne fonctionne pas car le serveur tourne sous linux et on a besoin de word) -->
	<iframe title="PDF" class="pdf-container" :src="`${this.file_url}`"></iframe>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
// import mammoth from 'mammoth';	// pour visualiser docx
import PopupNotification from '@/components/Utils/PopupNotification.vue';
import {BACK_URL} from '@/utils/ip'

export default {
	name: "ResumePreview",
	data() {
		return {
			docxContent: '',
			file_url: '',
			loading: false,
		}
	},
	components: {
		PopupNotification
	},
	created() {

	},
	computed: {
		...mapGetters({
			user: 'getUserData',
			resume_data: 'getResumeData',
			empty_resume: 'getEmptyResume'
		}),
	},
	mounted() {
		this.updatePreview()
	},
	methods: {
		updatePreview() {
			this.loading = true;
			axios.post(`${BACK_URL}/api/update_preview`, { 
				userId: this.user.id,
				data: this.resume_data ?? this.empty_resume,
				pdfSettings : {
					removePage: document.getElementById("remove_page_checkbox") != null ? document.getElementById("remove_page_checkbox").checked : false,
					tolerance: document.getElementById("tolerance-value") != null ? document.getElementById("tolerance-value").value : 0,
					lineSpacing: document.getElementById("line-spacing-value") != null ? document.getElementById("line-spacing-value").value : 10,
					spaceBefore: document.getElementById("space-before-value") != null ? document.getElementById("space-before-value").value : 10,
				}
				
			}, {
				responseType: 'blob',
				headers: {
					'Content-Type': 'application/json'
				}
			}).then((response) => {
				// url pdf
				this.file_url = window.URL.createObjectURL(response.data);
			}).catch((error) => {
				try {
					console.error(error);
					this.$refs.notification.addNotification('Une erreur est survenue lors de la mise à jour de la preview: ' + error.message, 'error', 3000);
				} catch {
					console.log("")
				}
				
			}).finally(() => {
				this.loading = false;
			})
		},
		
	},
}
</script>

<style>
.pdf-container {
	width: 100%;
	height: calc(99vh - 3rem);
	margin: -1rem;
	border: none;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	overflow: hidden;
}

.button-icon {
  width: 24px;
  height: 24px;
  color: inherit;
  margin: -0.5rem 0.3rem -0.5rem -0.3rem;
}

.update_button {
    position: absolute;
    bottom: 58px;
    right: 0;
	border-radius: 10px 0px 0px 0px;
}

.orange_button:hover .button-icon {
  filter: brightness(0) saturate(100%) invert(10%) sepia(63%) saturate(1821%) hue-rotate(177deg) brightness(94%) contrast(102%);
  opacity: 1.0;
  transition: filter 0.1s;
}

</style>
