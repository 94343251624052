<template>
	<button type="button" class="collapsible" id="lang_collaps">Langues</button>
	<div class="content">
		<div class="form-group" v-for="(language, language_index) in languageList" :key="language_index" >
			<div class="side_by_side_container" style="flex-wrap: nowrap;">
				<div class="input-wrapper">
					<span class="input_circle"></span>
					<span class="label text required" :class="{ 'hidden': language.language.isLabelHidden }">{{ language.language.label }}</span>
					<input type="text" :name="language.language.name + language_index" v-model="language.language.value" @focus="toggleLabel(language_index)" @blur="toggleLabel(language_index)"  :readonly="this.isReadOnly" v-on:input="this.$emit('edited')">
				</div>
				<div class="select-dropdown">
					<select class="selector" :name="language.level.name" v-model="language.level.value" :disabled="this.isReadOnly">
						<option value="A1">A1</option>
						<option value="A2">A2</option>
						<option value="B1">B1</option>
						<option value="B2">B2</option>
						<option value="C1">C1</option>
						<option value="C2">C2</option>
						<option value="Débutant">Débutant</option>
						<option value="Confirmé">Confirmé</option>
						<option value="Courant">Courant</option>
						<option value="Maternelle">Maternelle</option>
					</select>
				</div>
				<button class="delete_button" type="button" @click="removeLanguage(language_index)" v-on:click="this.$emit('edited')" :disabled="this.isReadOnly"></button>
			</div>
		</div>
		<button class="orange_button" type="button" @click="addLanguage" :disabled="this.isReadOnly" v-on:click="this.$emit('edited')">Ajouter une langue</button>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	name: "LanguagesForm",
	props: ['isReadOnly'],
	emits: ['edited', 'input', 'click'],
	// eslint-disable-next-line no-unused-vars
	setup(props) {
	},
	data() {
		return {
			languageList: [
				{
					language: {name: 'lang_language', label: 'Langue', isLabelHidden: false}, 
					level: {name: 'lang_level', label: 'Niveau'}
				}
			]
		};
	},
	computed: {
		...mapGetters({
			languages: 'getLanguagesData',
			empty_resume: 'getEmptyResume'
		})
	},
	created() {
		if (this.languages) {
			this.languageList = []
			this.languages.forEach(element => {
				this.languageList.push({
					language: {name: 'lang_language', label: 'Langue', isLabelHidden: element["language"] != "", value: element["language"]},
					level: {name: 'lang_level', label: 'Niveau', value: element["level"]}
				})
			});
		}
	},
	mounted() {
		var langContent = document.getElementById("lang_collaps");
		langContent.addEventListener("click", function () {
			this.classList.toggle("active");
			var content = this.nextElementSibling;
			if (content.style.display === "block") {
				content.style.display = "none";
			} else {
				content.style.display = "block";
			}
		});
	},
	methods: {
		...mapActions(['updateLanguagesFormData']),
		addLanguage() {
			this.languageList.push({language: {name: 'lang_language', label: 'Langue', isLabelHidden: false}, level: {name: 'lang_level', label: 'Niveau', isLabelHidden: false}});
		},
		removeLanguage(index) {
			this.languageList.splice(index, 1);
		},
		toggleLabel(index) {
			const inputElement = document.querySelector(`input[name="${this.languageList[index].language.name + index}"]`);
			const hasText = inputElement.value.trim() !== '';
			
			if (!hasText)
				this.languageList[index].language.isLabelHidden = !this.languageList[index].language.isLabelHidden;
		},
		updateData() {
			let new_data = [];

			for (let i = 0; i < this.languageList.length; i++) {
				new_data.push({
					language: this.languageList[i].language.value,
					level: this.languageList[i].level.value
				})
			}
			if (this.languageList.length == 0) {
				new_data.push(this.empty_resume.languages[0])
			}
			this.updateLanguagesFormData(new_data);
		}
	},
	watch: {
		languageList: {
			deep: true,
			handler() {
				this.updateData();
			}
		}
	}
}
</script>

<style>
select.selector {
    margin-left: 15px;
    margin-right: 15px;
    padding: 5px;
}
</style>
