<template>
	<div v-if="isVisible" class="password-validator-popup text">
		<h3>Vérification du mot de passe</h3>
		<ul>
			<li :class="{ valid: hasMinLength }">
				Au moins 8 caractères
			</li>
			<li :class="{ valid: hasUpperCase }">
				Au moins une majuscule
			</li>
			<li :class="{ valid: hasLowerCase }">
				Au moins une minuscule
			</li>
			<li :class="{ valid: hasNumber }">
				Au moins un chiffre
			</li>
			<li :class="{ valid: hasSpecialChar }">
				Au moins un caractère spécial
			</li>
			<li :class="{ valid: passwordsMatch }">
				Les mots de passe correspondent
			</li>
		</ul>
	</div>
</template>

<script>
import { computed, defineExpose } from 'vue';

export default {
	props: {
		password: {
			type: String,
			required: true
		},
		confirmPassword: {
			type: String,
			required: true
		},
		isVisible: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			hasMinLength: false,
			hasUpperCase: false,
			hasLowerCase: false,
			hasNumber: false,
			hasSpecialChar: false,
			passwordsMatch: false,
		}
	},
	computed: {
		isPasswordValid() {
			return this.hasMinLength && this.hasUpperCase && this.hasLowerCase &&
				this.hasNumber && this.hasSpecialChar && this.passwordsMatch;
		}
	},
	watch: {
		password: {
			immediate: true,
			handler: 'checkPasswords'
		},
		confirmPassword: {
			handler: 'checkPasswords'
		}
	},
	methods: {
		checkPasswords() {
			this.hasMinLength = this.password.length >= 8;
			this.hasUpperCase = /[A-Z]/.test(this.password);
			this.hasLowerCase = /[a-z]/.test(this.password);
			this.hasNumber = /[0-9]/.test(this.password);
			this.hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(this.password);
			this.passwordsMatch = this.password === this.confirmPassword && this.password !== '';
		},
	},
	setup() {
		const isPasswordValid = computed(() => {
			return this.isPasswordValid;
		});

		defineExpose({
			isPasswordValid
		});
	}
}
</script>

<style scoped>
.password-validator-popup {
	position: absolute;
	right: -220px;
	top: 0;
	width: 200px;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 10px;
	font-size: 0.8em;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h3 {
	margin-top: 0;
	font-size: 1em;
}

ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

li {
	margin-bottom: 5px;
	color: red;
}

li.valid {
	color: green;
}
</style>
