<template>
  <div id="app">
    <headerView />
    <router-view/>
  </div>
</template>

<script>
import headerView from "@/components/headerView.vue"

export default {
    components: {
        headerView,
    },
}
</script>

<style>
body {
    margin: 0;
}

.required:after {
    content: " *";
    color: #f9a825;
}

#app {
  font-family: Oswald-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #002339;
}

nav {
  padding: 1.875rem;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.orange_button {
    padding: 10px;
    background-color: #f9a825;
    border: 0px;
    color: #fff;
    text-transform: uppercase;
    font-size: 11px !important;
    letter-spacing: 0.125rem;
    float: center;
    clear: both;
    cursor: pointer;
    text-wrap: nowrap;
    border-radius: 10px;
}

.orange_button.head {
    padding: 1.25rem 2.8125rem;
    background-color: #f9a825;
    border: 0px;
    color: #fff;
    text-transform: uppercase;
    font-size: 11px !important;
    letter-spacing: 0.125rem;
    float: center;
    clear: both;
    cursor: pointer;
    border: solid 2px #e59b24;
}

.orange_button.head:hover {
    height: 59px;
}

.orange_button:hover {
    color: #002339;
    transition: all 0.3s ease;
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate both;
    z-index: 30;
}

.orange_button.head:active {
    color: #002339;
    transition: all 0.3s ease;
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) alternate both;
    z-index: 30;
    height: 59px;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}

.login-container {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.25rem;
}

.form-container {
	background-color: white;
	border-radius: 0.625rem;
	box-shadow: 0.625rem 0.625rem 2.5rem rgba(0, 0, 0, 0.18);
	padding: 1.25rem;
	width: 35%;
	align-items: center;
	display: flex;
	flex-direction: column;
}

input {
    outline: none !important;
    border: 0;
    border-bottom: 0.0625rem solid #00233930;
    background-color: transparent;
    color: #002339;
    font-size: 13px !important;
    letter-spacing: 0.0625rem !important;
    padding: 2px;
    /* width: 100%; */
}

.text {
    font-family: sans-serif;
    color: #0b2c42;
}

.label {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0.9375rem;
    transform-origin: left;
    transition: transform 0.3s ease, color 0.3s ease;
    pointer-events: none;
}

.label.hidden {
    transform: translateY(calc(100% + 0.75rem)) scale(0.8);
    color: #002339;
}

.input_circle {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #002339;
  margin-right: 0.3125rem;
}

.input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.blur-background {
    filter: blur(5px);
    transition: filter 0.3s ease;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.33);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    text-align: -webkit-center;
}

.cancel-button {
    padding: 10px;
    background-color: #fff;
    border: 2px solid #002339;
    color: #002339;
    text-transform: uppercase;
    font-size: 11px !important;
    letter-spacing: 0.125rem;
    float: center;
    clear: both;
    cursor: pointer;
    text-wrap: nowrap;
}

.cancel-button:hover {
    color: #fff;
    background-color: #002339;
    transition: all 0.3s ease;
}
</style>
