<template>
    <div style="width: 50%;margin: auto;">
        <h1 class="text policy_title">Politique de confidentialité</h1>
        <section class="policy_section">
            <p class="text policy_paragraph">
                Cette politique de confidentialité a pour objectif de vous expliquer pourquoi nous recueillons vos données et comment nous nous engageons à les protéger.
            </p><p class="text policy_paragraph">
                D.R.A. Technologies s’engage en faveur de la protection de vos données personnelles et de votre vie privée.
            </p><p class="text policy_paragraph">
                À ce titre, et en application du Règlement Général de Protection des Données (ci-après dénommé « RGPD »), nous vous communiquons ci-après les conditions dans lesquelles vos données personnelles sont appelées à être traitées par nos soins.
            </p>
        </section>

        <hr />

        <h2 class="text policy_title">Quelles données personnelles traitons-nous ?</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                Nous sommes susceptibles de recueillir et de conserver vos données à caractère personnel, notamment pour :
            </p>
            <ul>
                <li class="text policy_list">Traiter et répondre à vos messages</li>
                <li class="text policy_list">Créer et gérer l’accès à vos comptes</li>
                <li class="text policy_list">Etablir et assurer le suivi de la relation commerciale pouvant découler de vos messages</li>
                <li class="text policy_list">Traiter votre candidature à un emploi</li>
                <li class="text policy_list">Améliorer notre suivi et service client</li>
                <li class="text policy_list">Gérer le bon fonctionnement et la personnalisation des services</li>
                <li class="text policy_list">Traiter et répondre à vos demandes d’exercice de droits</li>
                <li class="text policy_list">Pour répondre aux exigences réglementaires en vigueur ou en cours d’adoption</li>
            </ul>
            <p class="text policy_paragraph">
                Concernant vos coordonnées, seul votre prénom est stocké. De plus nous enregistrons des informations personnelles comme par exemple votre nationalité, profession ou parcours scolaire
            </p>
        </section>

        <hr />

        <h2 class="text policy_title">Fondement juridiques des traitements.</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                Les traitements de données à caractère personnel mis en œuvre sont fondés :
            </p>
            <ul>
                <li class="text policy_list">Soit sur le consentement de la personne concernée (Article 6.1.a du RGPD) pour tous les traitements qui nécessitent le recueil préalable du consentement. Dans les formulaires en ligne, les champs obligatoires sont marqués d’un astérisque. A défaut de réponse aux questions obligatoires, nous ne serons pas en mesure de vous fournir les services demandés.</li>
                <li class="text policy_list">Soit pour l’exécution d’un contrat ou l’exécution de mesures précontractuelles,</li>
                <li class="text policy_list">Soit pour la poursuite d’un intérêt légitime (Article 6.1.e du RGPD).</li>
                <li class="text policy_list">Soit pour le respect d’une obligation légale ou règlementaire</li>
            </ul>
            <p class="text policy_paragraph">
                Vos données sont conservées pour la durée nécessaire à l’accomplissement des finalités mentionnées ci-dessus.
            </p><p class="text policy_paragraph">
                La durée de conservation des données personnelles des Clients dépend de la finalité concernée. Dans ce cadre, les données personnelles des Clients sont conservées le temps nécessaire à l’accomplissement
                de leur requête. A défaut d’une quelconque réalisation, les données personnelles sont supprimées dans les délais recommandés par la Commission Nationale Informatique et Libertés (CNIL), au terme d’un délai de trois ans à compter de leur collecte, sous réserve : – des possibilités et obligations légales en matière d’archivage,
                – des obligations de conservation de certaines données, à des fins probatoires, et/ou d’anonymisation de
                celles-ci.
            </p><p class="text policy_paragraph">
                Les données personnelles du Client collectées et traitées, pour les besoins d’exécution des offres, sont conservées
                pour la durée nécessaire à la gestion de la relation contractuelle.
                Par dérogation, les données personnelles requises pour à l’établissement de la preuve d’un droit ou d’un contrat sont archivées conformément aux dispositions légales (5 ou 10 ans après la fin de la relation commerciale selon le cas).
            </p>
        </section>
        
        <hr />

        <h2 class="text policy_title">Quels sont les destinataires de vos données ?</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                Vos données personnelles sont destinées à D.R.A. Technologies.
            </p><p class="text policy_paragraph">
                <strong>Nos services internes :</strong> Elles sont traitées par le personnel de nos différents services tel que le service commercial ou le service en charge de la sécurité informatique.
            </p><p class="text policy_paragraph">
                <strong>Des entreprises ou personnes de confiance :</strong> ils traitent vos informations pour nous aux fins énoncées ci-dessus, conformément à nos instructions telles qu’elles sont décrites dans notre Politique de confidentialité et à tous autres cas d’usage approprié en termes de confidentialité et de sécurité.
            </p><!--<p class="text policy_paragraph">
                <strong>Des sous-traitants techniques :</strong> Les données personnelles vous concernant peuvent être transférées à nos sous-traitants techniques (au sens de l’article 4.8 du RGPD) de façon encadrée strictement. En cas de transfert, nous nous assurons que les sous-traitants respectent le RGPD et prennent des mesures technique et organisationnelle pour garantir la protection des données (art. 28 du RGPD).
            </p>--><p class="text policy_paragraph">
                Nous devons parfois permettre à nos partenaires de traiter, en notre nom, les informations personnelles que nous détenons sur vous aux fins énoncées dans cette politique ou pour toute autre raison requise par la loi.
            </p><p class="text policy_paragraph">
                Les données personnelles des Clients collectées sont hébergées en France.
            </p><p class="text policy_paragraph">
                Dans le cas du recours à un prestataire situé en dehors de l’Union européenne, nous nous engageons à vérifier que des mesures appropriées ont été mises en place afin que les données personnelles bénéficient d’un niveau de protection adéquat.
            </p>
        </section>

        <hr />

        <h2 class="text policy_title">Comment D.R.A. Technologies préserve la sécurité de vos données ?</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                Nous mettons en place toutes les mesures organisationnelles et techniques permettant d’assurer un niveau approprié de sécurité à vos données personnelles, et notamment d’éviter toute perte de confidentialité, d’intégrité ou d’accessibilité.
            </p>
            <ul>
                <li class="text policy_list">Nous effectuons fréquemment la sauvegarde des données,</li>
                <li class="text policy_list">Protection physique des locaux et contrôle à l’entrée,</li>
                <li class="text policy_list">Dans la mesure du possible, nous limitons l'accès aux informations personnelles aux seules personnes qui ont besoin de les traiter,</li>
                <li class="text policy_list">Met en place des mesures techniques et organisationnelles pour assurer que la conservation des données personnelles des Clients est sécurisée et ce, pendant la durée nécessaire à l’exercice des finalités poursuivies.</li>
            </ul>
        </section>

        <hr />

        <h2 class="text policy_title">Quels sont vos droits sur vos données personnelles ?</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                Conformément à la Réglementation Applicable, vous disposez des droits suivants :
            </p><p class="text policy_paragraph">
                <strong>Un droit de rectification :</strong> vous avez le droit d’obtenir la rectification des données inexactes vous concernant. Vous avez également le droit de compléter les données incomplètes vous concernant, en fournissant une déclaration complémentaire. En cas d’exercice de ce droit, nous nous engageons à communiquer toute rectification à l’ensemble des destinataires de vos données,
            </p><p class="text policy_paragraph">
                <strong>Un droit d’effacement :</strong> dans certains cas, vous avez le droit d’obtenir l’effacement de vos données. Cependant, ceci n’est pas un droit absolu et nous pouvons pour des raisons légales ou légitimes conserver ces données.
            </p><p class="text policy_paragraph">
                <strong>Un droit à la limitation du traitement :</strong> dans certains cas, vous avez le droit d’obtenir la limitation du traitement sur vos données,
            </p><p class="text policy_paragraph">
                <strong>Un droit à la portabilité des données :</strong> vous avez le droit de recevoir vos données que vous nous avez fournies, dans un format structuré, couramment utilisé et lisible par une machine, pour votre usage personnel ou pour les transmettre à un tiers de votre choix. Ce droit ne s’applique que lorsque le traitement de vos données est basé sur votre consentement, sur un contrat ou que ce traitement est effectué par des moyens automatisés,
            </p><p class="text policy_paragraph">
                <strong>Un droit d’opposition au traitement :</strong> vous avez le droit de vous opposer à tout moment au traitement de vos données pour les traitements basés sur notre intérêt légitime, une mission d’intérêt public et ceux à des fins de prospection commerciale. Ceci n’est pas un droit absolu et nous pouvons pour des raisons légales ou légitimes refuser votre demande d’opposition,
            </p><p class="text policy_paragraph">
                <strong>Le droit de retirer votre consentement à tout moment :</strong> vous pouvez retirer votre consentement au traitement de vos données lorsque le traitement est basé sur votre consentement. Le retrait du consentement ne compromet pas la licéité du traitement fondé sur le consentement effectué avant ce retrait,
            </p><p class="text policy_paragraph">
                <strong>Le droit de déposer une plainte auprès d’une autorité de contrôle :</strong> vous avez le droit de contacter votre autorité de protection des données pour vous plaindre de nos pratiques de protection des données personnelles,
            </p><p class="text policy_paragraph">
                En application du <a href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees">RGPD</a>, les conditions d’exercice de ces droits peuvent varier selon la base de licéité du traitement mentionné dans le premier paragraphe.
                Nous donnerons suite à tout exercice de droit dans les meilleurs délais et en tout état de cause dans un délai de 30 jours à compter de la réception de la demande.
            </p><p class="text policy_paragraph">
                Nous nous réservons le droit :
            </p>
            <ul>
                <li class="text policy_list">De demander une preuve de l’identité du demandeur en cas de doute raisonnable sur cette dernière et ce afin de respecter son obligation de confidentialité,</li>
                <li class="text policy_list">De prolonger le délai de réponse de deux mois, informant alors le demandeur de cette prolongation et des motifs du report dans un délai d’un mois à compter de la réception de la demande,</li>
                <li class="text policy_list">De refuser de répondre à un exercice de droit si celui-ci était considéré comme abusif (au vu de leur nombre, de leur caractère répétitif ou systématique).</li>
            </ul>
        </section>

        <hr />

        <h2 class="text policy_title">Qui contacter pour toutes les demandes liées au RGPD ?</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                Pour exercer vos droits, vous pouvez nous contacter :
            </p><p class="text policy_paragraph">
                D.R.A. Technologies
            </p><p class="text policy_paragraph">
                SAS au capital de 104 000 euros.<br />
                Immatriculée au Registre du commerce et des sociétés de Lyon B 528 911 738 sous le numéro SIRET 528 911 738 00037.<br />
                Adresse : 2355 Avenue Henri Schneider 69333, Meyzieu<br />
                Téléphone : +33 (0) 4 78 93 13 07<br />
                Directeur de la publication : J. Dorne
            </p><p class="text policy_paragraph">
                Si, en dépit de nos efforts et de nos engagements, vous estimiez que vos droits concernant vos données personnelles n’étaient pas respectés, vous pouvez adresser une réclamation auprès de la Commission Nationale Informatique et Libertés : CNIL 3 Place de Fontenoy TSA 80715 75334 Paris Cedex 07
            </p>
        </section>

        <hr />

        <h2 class="text policy_title">Réserve de modification de la Politique de protection des données.</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                La présente Politique de protection des données personnelles peut être amenée à évoluer. Elles ont été élaborées à partir d’un modèle libre qui peut être téléchargé sur le site <a href="https://donnees.net">https://donnees.net</a>. Comme nous développons constamment nos services, nous nous réservons le droit de modifier cette Politique de confidentialité, conformément aux dispositions légales en vigueur. Toute modification est publiée sur ce document en temps opportun. Nous vous conseillons de consulter régulièrement cette page pour prendre connaissance des éventuelles modifications ou mises à jour apportées à notre politique de confidentialité.
            </p>
        </section>
    </div>
</template>

<script>

export default {
	name: "PrivacyPolicy",
    mounted() {
        let nom = "D.R.A. Technologies"
        var element = document.getElementsByClassName("policy_paragraph")

        for (let index = 0; index < element.length; index++) {
            let contenuModifie = element[index].innerHTML.replace(new RegExp(nom, 'g'), `<strong>${nom}</strong>`);
            element[index].innerHTML = contenuModifie;
        }
    }
}
</script>

<style scoped>
li.text.policy_list {
    text-align: start;
}

p.text.policy_paragraph {
    text-align: start;
    line-height: 25px;
}

section.policy_section {
    text-align: start;
}

h1.text.policy_title {
    font-size: 40px;
    text-decoration: underline 3px solid #f9a825;
    margin-bottom: 60px;
}
</style>
