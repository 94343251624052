import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from "@/views/LoginView.vue"
import AdminView from '@/views/AdminView.vue'
import ProfileView from '@/views/ProfileView.vue'
import ResetPasswordView from '@/views/ResetPasswordView.vue'
import MentionsLegales from '@/components/Utils/MentionsLegales.vue'
import PrivacyPolicy from '@/components/Utils/PrivacyPolicy.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	},
	{
		path: "/login",
		name: "login",
		component: LoginView
	},
	{
		path: "/admin",
		name: "admin",
		component: AdminView
	},
	{
		path: "/profile",
		name: "profile",
		component: ProfileView
	},
	{
		path: '/reset_password',
		name: 'reset_password',
		component: ResetPasswordView
	},
	{
		path: "/policy",
		name: "policy",
		component: MentionsLegales
	},
	{
		path: "/privacy",
		name: "privacy",
		component: PrivacyPolicy
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
