<template>
    <section title="Saut de page ?" class="section_checkbox text">
        <p v-if="this._label != undefined" class="text" style="margin-bottom: 5px;">{{ this._label }}</p>
        <div class="slider-checkbox text">
            <input :id="this._id" class="slide_exp_checkbox" type="checkbox" :checked="this._checked" v-on:input="this.$emit('edited')" />
            <label :for="this._id">
                <span id="ball"></span>
            </label>
        </div>
    </section>
</template>

<script>

export default {
    name: 'SlideCheckBox',
    props: {
        _id: {
            type: String,
            required: true
        },
        _label: {
            type: String,
            required: false
        },
        _checked: {
            type: Boolean,
            required: true
        },
    },
    emits: ['edited'],
    data() {
        return {
            isEdited: false,
        }
    },
}
</script>


<style>
/* Slide 5 */
.slider-checkbox {
    width: 3em;
    height: 1.5em;
    /* margin: 20px 5px; */
}

.slider-checkbox * {
	transition: 250ms;
	box-sizing: border-box;
}

.slider-checkbox input[type="checkbox"] {
	display: none;
}

.slider-checkbox label {
	display: inline-block;
	width: 100%;
	height: 95%;
	background: grey;
	border-radius: 0.7em;
	padding-top: 0.2em;
	padding-left: 0.2em;
}

.slider-checkbox span {
    display: inline-block;
    width: 1em;
    height: 1em;
    background: #FFF;
    border: none;
    border-radius: 2em;
    position: relative;
    left: -0.8em;
}

.slider-checkbox input[type="checkbox"]:checked ~ label {
	background: #f9a927;
	padding-left: 2.5em;
}

.section_checkbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: auto; */
    /* margin: 20px auto; */
    justify-content: center;
    width: fit-content;
}
</style>