<template>
	<div>
		<ResetPasswordForm />
	</div>
</template>

<script>
import ResetPasswordForm from "@/components/User/ResetPasswordForm.vue"

export default {
	name: "ResetPasswordView",
	components: {
		ResetPasswordForm,
	}
}
</script>

<style>

</style>
