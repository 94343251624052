<template>
	<PopupNotification ref="notification"/>
	<div class="login-container">
		<div class="form-container">
			<form>
				<h1 class="text">Réinitialisation du mot de passe</h1>
				<div class="form-group" v-for="(field, index) in formFields" :key="index">
					<div class="input-wrapper">
						<span class="input_circle"></span>
						<span class="label text required" :class="{ 'hidden': field.isLabelHidden }">{{ field.label }}</span>
						<input :type="field.type" :id="field.id" v-model="field.value" :name="field.name" style="width: 100%;" @focus="toggleLabel(index)" @blur="toggleLabel(index)" required />
					</div>
					<PasswordValidator v-if="field.title === 'Mot de passe'" ref="passwordValidator" :password="field.fields[1].value" :confirmPassword="field.fields[2].value" :isVisible="showPasswordValidator" @close="showPasswordValidator = false" style="position: absolute; left: 70%; top: 20%;" />
				</div>
				<button class="orange_button" type="button" @click="reset_password">Réinitialiser</button>
			</form>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import PopupNotification from '@/components/Utils/PopupNotification.vue';
import PasswordValidator from '@/components/Utils/PasswordValidator.vue';
import {BACK_URL} from '@/utils/ip'

export default {
	name: "ResetPasswordForm",
	data() {
		return {
			token: "",
			formFields: [
				{ name: "password", type: "password", id: "password", value: "", label: "Mot de passe", isLabelHidden: false },
				{ name: "password_confirm", type: "password", id: "password_confirm", value: "", label: "Confirmation mot de passe", isLabelHidden: false }
			],
		};
	},
	components: {
		PopupNotification,
		PasswordValidator
	},
	created() {
		if (this.$route.query.token) {
			this.token = this.$route.query.token;
		}
		else {
			this.$router.push('/');
		}
	},
	methods: {
		toggleLabel(index) {
			const inputElement = document.querySelector(`input[name="${this.formFields[index].name}"]`);
			const hasText = inputElement.value.trim() !== '';

			if (!hasText)
				this.formFields[index].isLabelHidden = !this.formFields[index].isLabelHidden;
			if (hasText && !this.formFields[index].isLabelHidden)
				this.formFields[index].isLabelHidden = true;
		},
		checkPassword(password, confirmPassword) {
			let hasMinLength = password.length >= 8;
			let hasUpperCase = /[A-Z]/.test(password);
			let hasLowerCase = /[a-z]/.test(password);
			let hasNumber = /[0-9]/.test(password);
			let hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
			let passwordsMatch = password === confirmPassword && password !== '';

			return hasMinLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && passwordsMatch;
		},
		reset_password() {
			if (this.checkPassword(this.formFields[0].value, this.formFields[1].value)) {
				axios.post(`${BACK_URL}/api/reset_password`, {
					token: this.token,
					password: this.formFields[0].value
				}).then(() => {
					this.$router.push('/login');
				}).catch(error => {
					this.$refs.notification.addNotification("Erreur lors de la réinitialisation du mot de passe: " + error.response.data.message, 'error', 3000);
					console.error('Error resetting password:', error);
				})
			} else {
				this.$refs.notification.addNotification("Votre mot de passe doit contenir une majuscule, une minuscule, un chiffre et un charactère spécial.", 'Mot de passe faible', 3000);
			}
		},
	}
}
</script>

<style>

</style>
