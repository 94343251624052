<template>
    <div style="width: 50%;margin: auto;">
        <h1 class="text policy_title">Mentions légales</h1>
        <h2 class="text policy_title">1 - Édition du site.</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site internet <a href="/" class="text">https://cv.dra.fr</a> l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi:
            </p><p class="text policy_paragraph">
                <strong>Propriétaire du site :</strong> D.R.A. Technologies - Contact : +33 4 78 93 13 07 - Adresse : 2355 Avenue Henri Schneider - 69330 Meyzieu.
            </p><p class="text policy_paragraph">
                <strong>Identification de l'entreprise :</strong> SAS D.R.A. Technologies au capital social de 104 000€ - SIREN : 528 911 738 00037 - RCS ou RM : - Adresse postale : 2355 Avenue Henri Schneider - 69330 Meyzieu
            </p><p class="text policy_paragraph">
                <strong>Directeur de la publication :</strong> D.R.A. Technologies - Contact : +33 (0) 4 78 93 13 07.
            </p><p class="text policy_paragraph">
                <strong>Hébergeur :</strong> OVH SAS - 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1 - Téléphone : 1007
            </p><p class="text policy_paragraph">
                <strong>Délégué à la protection des données :</strong> J. Dorne
            </p>
        </section>

        <hr />

        <h2 class="text policy_title">2 - Propriété intellectuelle et contrefaçons.</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                D.R.A. Technologies est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur tous les éléments accessibles sur le site internet, notamment les textes, images, graphismes, logos, vidéos, architecture, icônes et sons.
            </p><p class="text policy_paragraph">
                Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de D.R.A. Technologies.
            </p><p class="text policy_paragraph">
                Toute exploitation non autorisée du site ou de quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
            </p>
        </section>

        <hr />

        <h2 class="text policy_title">3 - Limitations de responsabilité.</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                D.R.A. Technologies ne pourra être tenu pour responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site <a href="/" class="text">https://cv.dra.fr</a>.
            </p><p class="text policy_paragraph">
                D.R.A. Technologies décline toute responsabilité quant à l’utilisation qui pourrait être faite des informations et contenus présents sur <a href="/" class="text">https://cv.dra.fr</a>.
            </p><p class="text policy_paragraph">
                D.R.A. Technologies s’engage à sécuriser au mieux le site <a href="/" class="text">https://cv.dra.fr</a>, cependant sa responsabilité ne pourra être mise en cause si des données indésirables sont importées et installées sur son site à son insu.
            </p><p class="text policy_paragraph">
                Des espaces interactifs sont à la disposition des utilisateurs. D.R.A. Technologies se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données.
            </p><p class="text policy_paragraph">
                Le cas échéant, D.R.A. Technologies se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie …).
            </p>
        </section>
        
        <hr />

        <h2 class="text policy_title">4 - CNIL et gestion des données personnelles.</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                Conformément aux dispositions de la loi 78-17 du 6 janvier 1978 modifiée, l’utilisateur du site <a href="/" class="text">https://cv.dra.fr</a> dispose d’un droit d’accès, de modification et de suppression des informations collectées. Pour exercer ce droit, envoyez un message à notre Délégué à la Protection des Données : Jerome Dorne - .
            </p><p class="text policy_paragraph">
                Pour plus d'informations sur la façon dont nous traitons vos données (type de données, finalité, destinataire...), lisez notre <a href="/privacy" class="text">Politique de confidentialité</a>.
            </p>
        </section>

        <hr />

        <h2 class="text policy_title">5 - Liens hypertextes et cookies.</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                Le site <a href="/" class="text">https://cv.dra.fr</a> peut contenir des liens hypertextes vers d’autres sites et dégage toute responsabilité à propos de ces liens externes ou des liens créés par d’autres sites vers <a href="/" class="text">https://cv.dra.fr</a>.
            </p><p class="text policy_paragraph">
                La navigation sur le site <a href="/" class="text">https://cv.dra.fr</a> est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur.
            </p><p class="text policy_paragraph">           
                Un "cookie" est un fichier de petite taille qui enregistre des informations relatives à la navigation d’un utilisateur sur un site. Les données ainsi obtenues permettent d'obtenir des mesures de fréquentation, par exemple.
            </p><p class="text policy_paragraph">            
                Vous avez la possibilité d’accepter ou de refuser les cookies en modifiant les paramètres de votre navigateur. Aucun cookie ne sera déposé sans votre consentement.
            </p><p class="text policy_paragraph">           
                Les cookies sont enregistrés pour une durée maximale de 3 mois.
            </p><p class="text policy_paragraph">          
                Pour plus d'informations sur la façon dont nous faisons usage des cookies, lisez notre <a href="/privacy" class="text">Politique de confidentialité</a>
            </p>
        </section>

        <hr />

        <h2 class="text policy_title">6 - Droit applicable et attribution de juridiction.</h2>
        <section class="policy_section">
            <p class="text policy_paragraph">
                Tout litige en relation avec l’utilisation du site <a href="/" class="text">https://cv.dra.fr</a> est soumis au droit français. En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux tribunaux compétents de Lyon.
            </p>
        </section>
    </div>
</template>

<script>

export default {
	name: "MentionsLegales",
    mounted() {
        let nom = "D.R.A. Technologies"
        var element = document.getElementsByClassName("policy_paragraph")

        for (let index = 0; index < element.length; index++) {
            let contenuModifie = element[index].innerHTML.replace(new RegExp(nom, 'g'), `<strong>${nom}</strong>`);
            element[index].innerHTML = contenuModifie;
        }
    }
}
</script>

<style scoped>
p.text.policy_paragraph {
    text-align: start;
    line-height: 25px;
}

section.policy_section {
    text-align: start;
}

h1.text.policy_title {
    font-size: 40px;
    text-decoration: underline 3px solid #f9a825;
    margin-bottom: 60px;
}
</style>
