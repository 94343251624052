<template>
	<PopupNotification ref="notification" />
	<div :class="{ 'blur-background': isDeleteModalVisible }">
		<div class="title text" style="text-align: center; font-size: 25px;">{{ this.user.firstName }}</div>
		<div class="form_container" v-for="(field, index) in settingsFormFields" :key="index">
			<div class="title text" style=" position: relative; padding-left: 0; left: 20%; align-self: flex-start;">
				{{ field.title }}
			</div>
			<hr class="profile" style="width: 60%;" />
			<form class="side_by_side_container form-group" style=" width: 60%; justify-content: space-between; margin-top: 0.5%; flex-wrap: nowrap">
				<div class="input-wrapper" v-for="(field, index2) in field.fields" :key="index2">
					<span class="input_circle"></span>
					<span class="label text" :class="{ 'hidden': field.isLabelHidden }">{{ field.label }}</span>
					<input :type="field.type" :id="field.id" v-model="field.value" :name="field.name" @focus="handleInputFocus(index, index2)" @blur="handleInputFocus(index, index2)" style="width: 80%;">
				</div>
			</form>
			<button class="orange_button" type="button" @click=field.button_click>{{ field.button }}</button>
			<PasswordValidator ref="passwordValidator" v-if="field.title === 'Mot de passe'" :password="field.fields[1].value" :confirmPassword="field.fields[2].value" :isVisible="showPasswordValidator" @close="showPasswordValidator = false" style="position: absolute; left: 80%; top: 17%;" />
		</div>
		<hr class="profile" style="width: 60%;" />
		<div class="form_container">
			<button class="orange_button" type="button" @click="downloadResume">Télécharger le CV</button>
		</div>

		<div class="form_container" style="padding: 0;">
			<button class="orange_button" type="button" @click="showDeleteModal" style="background-color: red;align-self: self-end;margin-right: 20%;">
				Supprimer le compte
			</button>
		</div>

		<div class="form_container" style="position: absolute;bottom: 0;flex-direction: row;width: -webkit-fill-available;justify-content: center;">
			<a href="/policy" class="text" style="margin: 10px;">Mentions légales</a>
			<a href="/privacy" class="text" style="margin: 10px;">Politique de confidentialité</a>
			<p class="text" style="margin: 10px;">{{ this.version }}</p>
		</div>
	</div>

	<div v-if="isDeleteModalVisible" class="modal">
		<div class="modal-content">
			<h2>Confirmer la suppression</h2>
			<p>La suppression du compte entraînera la suppression de toutes vos données du serveur. Cette action est irréversible.</p>
			<p>Pour confirmer la suppression, veuillez entrer votre mot de passe.</p>
			<div style="max-width: fit-content; margin: 5% 0%;">
				<div class="input-wrapper">
					<span class="input_circle"></span>
					<span class="label text required" :class="{ 'hidden': deleteFormFields.isLabelHidden }">
						Mot de passe
					</span>
					<input type="password" :id="deleteFormFields.id" v-model="deleteFormFields.value" :name="deleteFormFields.name" @focus="toggleLabel()" @blur="toggleLabel()" required />
				</div>
			</div>
			<div>
				<button @click="confirmDeleteAccount" class="orange_button"  style="background-color: red; color: white; margin-right: 1rem;">Confirmer</button>
				<button @click="isDeleteModalVisible = false" class="cancel-button">Annuler</button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import PopupNotification from '@/components/Utils/PopupNotification.vue';
import PasswordValidator from '@/components/Utils/PasswordValidator.vue';
import {BACK_URL} from '@/utils/ip'
import {VERSION} from '@/utils/version'

export default {
	name: "ProfileSettingsForm",
	data() {
		return {
			settingsFormFields: [
				{
					title: "Mot de passe",
					fields: [
						{ name: "password", type: "password", id: "password", value: "", label: "Mot de passe actuel", isLabelHidden: false },
						{ name: "new_password", type: "password", id: "new_password", value: "", label: "Nouveau mot de passe", isLabelHidden: false },
						{ name: "confirm_password", type: "password", id: "confirm_password", value: "", label: "Confirmer le mot de passe", isLabelHidden: false }

					],
					button: "Modifier le mot de passe",
					button_click: this.updatePassword,
					response: { response: "", color: "", showMsg: false },
				}
			],
			deleteFormFields: {
				name: "delete_account_password", type: "password", id: "delete_account_password", value: "", label: "Mot de passe", isLabelHidden: false
			},
			isDeleteModalVisible: false,
			error: { messages: [], show: false },
			showPasswordValidator: false,
			version: VERSION
		};
	},
	components: {
		PopupNotification,
		PasswordValidator
	},
	computed: {
		...mapGetters({
			user: 'getUserData',
		})
	},
	mounted() {
		axios.get(`${BACK_URL}/api/user/${this.user.id}`)
		.then(response => {
			this.user.firstName = response.data.firstName
			this.user.everwinNum = response.data.everwinNum
		})
		.catch((error) => {
			console.error('Error fetching data:', error)
			this.$refs.notification.addNotification("Erreur lors de la récupération des données.", "error", 3000);
		});
	},
	methods: {
		...mapActions(['updateUserData']),
		handleInputFocus(index, index2) {
			if (this.settingsFormFields[index].title === "Mot de passe" && (index2 === 1 || index2 === 2)) {
				this.showPasswordValidator = !this.showPasswordValidator;
			}
			this.toggleFormLabel(index, index2);
		},
		toggleFormLabel(index, index2) {
			const inputElement = document.querySelector(`input[name="${this.settingsFormFields[index].fields[index2].name}"]`);
			const hasText = inputElement.value.trim() !== '';

			if (!hasText)
				this.settingsFormFields[index].fields[index2].isLabelHidden = !this.settingsFormFields[index].fields[index2].isLabelHidden;
		},
		toggleLabel() {
			const inputElement = document.querySelector(`input[name="${this.deleteFormFields.name}"]`);
			const hasText = inputElement.value.trim() !== '';

			if (!hasText)
				this.deleteFormFields.isLabelHidden = !this.deleteFormFields.isLabelHidden;
		},
		checkPassword(password, confirmPassword) {
			let hasMinLength = password.length >= 8;
			let hasUpperCase = /[A-Z]/.test(password);
			let hasLowerCase = /[a-z]/.test(password);
			let hasNumber = /[0-9]/.test(password);
			let hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
			let passwordsMatch = password === confirmPassword && password !== '';

			return hasMinLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && passwordsMatch;
		},
		updatePassword() {
			if (this.checkPassword(this.settingsFormFields[0].fields[1].value, this.settingsFormFields[0].fields[2].value)) {
				axios.post(`${BACK_URL}/api/update_password`, {
					userId: this.user.id,
					password: this.settingsFormFields[0].fields[0].value,
					new_password: this.settingsFormFields[0].fields[1].value
				}, {
					headers: {
						'Content-Type': 'application/json'
					}
				}).then((response) => {
					this.$refs.notification.addNotification(response.data.message, 'success', 3000);
					this.settingsFormFields[0].fields[0].value = "";
					this.settingsFormFields[0].fields[1].value = "";
					this.settingsFormFields[0].fields[2].value = "";
					this.settingsFormFields[0].fields[0].isLabelHidden = false;
					this.settingsFormFields[0].fields[1].isLabelHidden = false;
					this.settingsFormFields[0].fields[2].isLabelHidden = false;
				}).catch((error) => {
					if (error.response) {
						this.$refs.notification.addNotification(error.response.data.message, 'error', 3000);
					} else {
						this.$refs.notification.addNotification('Une erreur est survenue lors de la modification du mot de passe', 'error', 3000);
					}
					console.error(error);
				});
			} else {
				this.$refs.notification.addNotification("Le nouveau mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.", 'error', 5000);
			}
		},
		downloadResume() {
			this.$refs.notification.addNotification("Votre téléchargement va commencer...", 'info', 5000);
			axios.post(`${BACK_URL}/api/download_resume`, {
				userId: this.user.id,
				initiator: localStorage.me
			}, {
				responseType: 'blob'
			}).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'CV.pdf');
				document.body.appendChild(link);
				link.click();
				window.URL.revokeObjectURL(url)
			}).catch((error) => {
				this.$refs.notification.addNotification('Une erreur est survenue lors du téléchargement du CV: ' + error.message, 'error', 3000);
				console.error(error);
			});
		},
		showDeleteModal() {
			this.isDeleteModalVisible = true
		},
		confirmDeleteAccount() {
			if (this.deleteFormFields.value != "") {
				axios.post(`${BACK_URL}/api/delete_user`, {
					userId: this.user.id,
					password: this.deleteFormFields.value
				}, {
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(() => {
					this.$refs.notification.addNotification("Compte supprimé.", 'success', 3000);
					this.updateUserData(null)
					localStorage.me = ""
					this.$router.push('/login');
				}).catch((error) => {
					if (error.response) {
						this.$refs.notification.addNotification(error.response.data.message, 'error', 3000);
					} else {
						this.$refs.notification.addNotification('Une erreur est survenue lors de la suppression du compte', 'error', 3000);
					}
					console.error(error);
				});
				this.deleteFormFields.value = "";
				this.deleteFormFields.isLabelHidden = false;
				this.isDeleteModalVisible = false
			}
		},
	}
}
</script>

<style>
.title {
	justify-content: center;
	padding-left: 12.5%;
	padding-top: 3rem;
	width: 75%;
	text-align: left;
	font-size: 1rem;
	text-transform: uppercase;
	/*border-bottom: 0.125rem solid #f9a825;*/
}

.form_container {
	padding: 1rem 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.response-container {
  height: 3rem; /* Ajustez cette valeur selon vos besoins */
  margin-top: 10px;
}
</style>
