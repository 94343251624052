<template>
	<ProfileSettingsForm />
</template>

<script>
import { mapGetters } from 'vuex';
import ProfileSettingsForm from "@/components/User/ProfileSettingsForm.vue"
import { user_exists } from "@/utils/user.js";

export default {
	name: "ProfileView",
	components: {
		ProfileSettingsForm
	},
	computed: {
		...mapGetters({
			user: 'getUserData',
		}),
	},
	created() {
		if (user_exists(this.user.id) == false) {
			this.$router.push({ name: 'login' });
		}
	}
}
</script>

<style>

</style>
