const ResumeStatus = {
    ON_GOING: 0,
    VALIDATE: 1,
    APPROVED: 2
}

export const convertResumeStatus = (num) => {
    switch (num) {
        case 0:
            return "En cours"
        case 1:
            return "Validé"
        case 2:
            return "Approuvé"
        default:
            return "This status does not exist"
    }
}

export default ResumeStatus