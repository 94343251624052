<template>
	<header>
		<router-link :to="{ path: '/' }">
			<div class="logo">
				<img src="@/assets/images/DRA_Technologies_Logo.svg" />
			</div>
		</router-link>
		<div v-if="is_connected(this.user == null ? null : this.user.id) == false">
			<router-link :to="{ path: '/login' }">
				<button class="orange_button head" style="border-radius: 0px 0px 0px 10px;">Se connecter</button>
			</router-link>
		</div>
		<div v-else>
			<router-link :to="{ path: '/' }">
				<button class="orange_button head" style="border-radius: 0px 0px 10px 10px;">Home</button>
			</router-link>
			<router-link :to="{ path: '/admin' }" v-if="this.isAdmin">
				<button class="orange_button head" style="border-radius: 0px 0px 10px 10px;">Panneau administrateur</button>
			</router-link>
			<router-link :to="{ path: '/profile' }">
				<button class="orange_button head" style="border-radius: 0px 0px 10px 10px;">Mon profil</button>
			</router-link>
			<button class="orange_button head" style="border-radius: 0px 0px 0px 10px;" @click="disconnect">Se deconnecter</button>
		</div>
	</header>
</template>

<script>
// import router from "@/router";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { user_exists } from "@/utils/user.js";
import { BACK_URL } from '@/utils/ip'

export default {
	name: "headerView",
	data() {
		return {
			isAdmin: false
		};
	},
	computed: {
		...mapGetters({
			user: 'getUserData',
		})
	},
	renderTriggered() {
		this.is_admin()
	},
	methods: {
		...mapActions(['updateUserData']),
		is_connected() {
			this.is_admin()
			return (user_exists(this.user == null ? null : this.user.id));
		},
		disconnect() {
			this.updateUserData({ id: "" });
			this.$router.push({ name: 'login' });
		},
		async is_admin() {
			if (this.user == null)
				return
			try {
				const res = await axios.post(`${BACK_URL}/api/is_admin`,
					{ userId: this.user == null ? null : this.user.id },
					{
						headers: {
							'Content-Type': 'application/json'
						}
					}
				)
				this.isAdmin = res.data.is_admin
			}
			catch {
				console.error("Cannot check admin")
			}
		},
	}
}
</script>

<style>
header {
	width: 100%;
	background-color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 3rem;
}

.logo {
    background-color: #fff;
    width: 13rem;
    height: 3rem;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 0% 0%;
    object-position: 0% 0%;
    position: absolute;
    top: -80px;
    left: -30px;
    z-index: 0;
}
</style>
