<template>
	<button type="button" class="collapsible" id="educ_collaps">Formations</button>
	<div class="content">
		<div class="form-group" v-for="(education, education_index) in educationList" :key="education_index" >
			<div class="side_by_side_container" style="justify-content: space-around;">
				<div v-for="(field, field_index) in education" :key="field_index">
					<div class="input-wrapper">
						<span class="input_circle"></span>
						<span class="label text required" :class="{ 'hidden': field.isLabelHidden }">{{ field.label }}</span>
						<div v-if="field.name === 'edu_year'" >
							<input type="text" pattern="\d*" maxlength="4" :name="field.name + education_index" v-model="field.value" @focus="toggleLabel(education_index, field_index)" @blur="toggleLabel(education_index, field_index)" style="width: 80%;" :readonly="this.isReadOnly"  v-on:input="this.$emit('edited')">
						</div>
						<div v-else>
							<input type="text" :name="field.name + education_index" v-model="field.value" @focus="toggleLabel(education_index, field_index)" @blur="toggleLabel(education_index, field_index)" style="width: 80%;" :readonly="this.isReadOnly"  v-on:input="this.$emit('edited')">
						</div>
					</div>
				</div>
				<button class="delete_button" type="button" @click="removeEducation(education_index)" :disabled="this.isReadOnly" v-on:click="this.$emit('edited')"></button>
			</div>
		</div>
		<button class="orange_button" type="button" @click="addEducation" :disabled="this.isReadOnly" v-on:click="this.$emit('edited')">Ajouter une formation</button>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	name: "EducationForm",props: ['isReadOnly'],
	emits: ['edited', 'input', 'click'],
	// eslint-disable-next-line no-unused-vars
	setup(props) {
	},
	data() {
		return {
			educationList: [
				[
					{name: 'edu_year', label: 'Année', isLabelHidden: false},
					{name: 'edu_school', label: 'École', isLabelHidden: false},
					{name: 'edu_degree', label: 'Diplôme', isLabelHidden: false},
				]
			]
		};
	},
	computed: {
		...mapGetters({
			education: 'getEducationData',
			empty_resume: 'getEmptyResume'
		})
	},
	created() {
		if (this.education) {
			this.educationList = []
			this.education.forEach(element => {
				this.educationList.push([
					{name: 'edu_year', label: 'Année', isLabelHidden: element["year"] != "", value: element["year"]},
					{name: 'edu_school', label: 'École', isLabelHidden: element["school"] != "", value: element["school"]},
					{name: 'edu_degree', label: 'Diplôme', isLabelHidden: element["degree"] != "", value: element["degree"]},
				])
			});
		}
	},
	mounted() {
		var educationContent = document.getElementById("educ_collaps");
		educationContent.addEventListener("click", function () {
			this.classList.toggle("active");
			var content = this.nextElementSibling;
			if (content.style.display === "block") {
				content.style.display = "none";
			} else {
				content.style.display = "block";
			}
		});
	},
	methods: {
		...mapActions(['updateEducationFormData']),
		addEducation() {
			this.educationList.push([
					{name: 'edu_year', label: 'Année', isLabelHidden: false},
					{name: 'edu_school', label: 'École', isLabelHidden: false},
					{name: 'edu_degree', label: 'Diplôme', isLabelHidden: false},
				]);
		},
		removeEducation(index) {
			this.educationList.splice(index, 1);
		},
		toggleLabel(index, field_index) {
			const inputElement = document.querySelector(`input[name="${this.educationList[index][field_index].name + index}"]`);
			const hasText = inputElement.value.trim() !== '';
			if (!hasText)
				this.educationList[index][field_index].isLabelHidden = !this.educationList[index][field_index].isLabelHidden;
		},
		updateData() {
			let new_data = []

			for (let i = 0; i < this.educationList.length; i++) {
				new_data.push({
					year: this.educationList[i][0].value,
					school: this.educationList[i][1].value,
					degree: this.educationList[i][2].value
				})
			}
			if (this.educationList.length == 0) {
				new_data.push(this.empty_resume.education[0])
			}
			this.updateEducationFormData(new_data);
		}
	},
	watch: {
		educationList: {
			deep: true,
			handler() {
				this.updateData();
			}
		}
	}
};
</script>

<style scoped>

</style>
