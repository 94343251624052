<template>
	<div>
		<LoginForm />
	</div>
</template>

<script>
import LoginForm from "@/components/User/LoginForm.vue"

export default {
	name: "LoginView",
	components: {
		LoginForm,
	}
}
</script>

<style>

</style>
