<template>
	<button type="button" class="collapsible" id="profile_collaps">Informations personnelles</button>
	<div class="content">
		<div class="side_by_side_container" style="justify-content: space-evenly;">
			<div class="form-group" v-for="(field, index) in formFields" :key="index">
				<div class="input-wrapper">
					<span class="input_circle"></span>
					<span class="label text required" :class="{ 'hidden': field.isLabelHidden }">{{ field.label }}</span>
					<input type="text" :name="field.name" v-model="field.value" @focus="toggleLabel(index)" @blur="toggleLabel(index)" :readonly="this.isReadOnly" v-on:input="this.$emit('edited')"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'ProfileForm',
	props: ['isReadOnly'],
	emits: ['edited', 'input'],
	// eslint-disable-next-line no-unused-vars
	setup(props) {
		
	},
	data() {
		return {
			formFields: [
				{ name: 'firstname', label: 'Prénom', isLabelHidden: false },
				{ name: 'position', label: 'Poste', isLabelHidden: false }
			],
		};
	},
	computed: {
		...mapGetters({
			resume_data: 'getResumeData',
			user: 'getUserData', 
		}),
	},
	created() {
		if (this.resume_data) {
			this.formFields = [
				{ name: 'firstname', label: 'Prénom', isLabelHidden: this.resume_data["profile"]["firstName"] != "", value: this.resume_data["profile"]["firstName"] },
				{ name: 'position', label: 'Poste', isLabelHidden: this.resume_data["profile"]["position"] != "", value: this.resume_data["profile"]["position"] }
			]
		}
	},
	mounted() {
		var profileContent = document.getElementById("profile_collaps");
		profileContent.addEventListener("click", function () {
			this.classList.toggle("active");
			var content = this.nextElementSibling;
			if (content.style.display === "block") {
				content.style.display = "none";
			} else {
				content.style.display = "block";
			}
		});
	},
	methods: {
		...mapActions(['updateProfileFormData']),
		toggleLabel(index) {
			const inputElement = document.querySelector(`input[name="${this.formFields[index].name}"]`);
			const hasText = inputElement.value.trim() !== '';
			if (!hasText)
				this.formFields[index].isLabelHidden = !this.formFields[index].isLabelHidden;
			if (hasText && !this.formFields[index].isLabelHidden)
				this.formFields[index].isLabelHidden = true;
		},
		fetchData() {
			/* récupérer les données les plus récentes entre le cache et le serveur */
		},
		updateData() {
			let new_data = {
				firstName: this.formFields[0].value,
				position: this.formFields[1].value
			}
			this.updateProfileFormData(new_data);
			this.$emit('input', true)
		}
	},
	// ajoute un watcher sur la variable "formFields", lorsque la variable change, appelle la fonction handler
	watch: {
		formFields: {
			deep: true, // pour que les sous-objets soient aussi observés
			handler() {
				this.updateData();
			}
		},
	}
};
</script>

<style>
	
</style>
