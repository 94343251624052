<template>
	<PopupNotification ref="notification" />
	<div class="login-container">
		<div class="form-container">
			<form v-if="formType === 'login'">
				<h1 class="text">Connexion</h1>
				<div class="form-group" v-for="(field, index) in formFields" :key="index">
					<div class="input-wrapper">
						<span class="input_circle"></span>
						<span class="label text required" :class="{ 'hidden': field.isLabelHidden }">{{ field.label }}</span>
						<input :type="field.type" :id="field.id" v-model="field.value" v-on:keyup.enter="login" :name="field.name" style="width: 100%;"
							@focus="toggleLabel(index)" @blur="toggleLabel(index)" required />
					</div>
				</div>
				<a href="/policy" class="text">Mentions légales</a>
				<a href="/privacy" class="text">Politique de confidentialité</a>
				<button class="orange_button" type="button" @click="login">Connexion</button>
			</form>
			<form v-else @submit.prevent>
				<h1 class="text">Réinitialisation de mot de passe</h1>
				<div class="form-group" v-for="(field, index) in formFieldsRP" :key="index">
				<div class="input-wrapper">
						<span class="input_circle"></span>
						<span class="label text required" :class="{ 'hidden': field.isLabelHidden }">{{ field.label }}</span>
						<input :type="field.type" :id="field.id" v-model="field.value" :name="field.name" style="width: 100%;" @focus="toggleLabelResetPass(index)"
							@blur="toggleLabelResetPass(index)" required />
					</div>
				</div>
				<button class="orange_button" type="button" @click="resetPassword">Réinitialiser le mot de passe</button>
					<a href="#" class="text" @click="formType = 'login'">Retour</a>
			</form>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex'
import PopupNotification from '@/components/Utils/PopupNotification.vue';
import {BACK_URL} from '@/utils/ip'

export default {
	name: "LoginForm",
	computed: {
		...mapGetters({
			user: 'getUserData',
		})
	},
	data() {
		return {
			formType: "login",
			email: "",
			formFields: [
				{ name: "login", type: "login", id: "login", value: "", label: "Identifiant", isLabelHidden: false },
				{ name: "password", type: "password", id: "password", value: "", label: "Mot de passe", isLabelHidden: false }
			],
			formFieldsRP: [
				{ name: "login", type: "login", id: "login", value: "", label: "Identifiant", isLabelHidden: false },
				{ name: "email", type: "email", id: "email", value: "", label: "Adresse mail", isLabelHidden: false }
			],
		};
	},
	components: {
		PopupNotification
	},
	mounted() {
		
		if(this.user != null && this.user.id != "") {
			this.$router.push({ path: '/' });
		}
	},
	methods: {
		...mapActions(['updateUserData']),
		resetPassword() {
			axios.post(`${BACK_URL}/api/request_reset_password`, {
				login: this.formFieldsRP[0].value,
				email: this.formFieldsRP[1].value
			}).then(() => {
				this.$refs.notification.addNotification('Un email de réinitialisation de mot de passe vous a été envoyé', 'success', 3000);
			}).catch((error) => {
				console.error('Error reseting password:', error)
				this.$refs.notification.addNotification(error.response.data.message, 'error', 3000);
			});
		},
		toggleLabel(index) {
			const inputElement = document.querySelector(`input[name="${this.formFields[index].name}"]`);
			const hasText = inputElement.value.trim() !== '';

			if (!hasText)
				this.formFields[index].isLabelHidden = !this.formFields[index].isLabelHidden;
			if (hasText && !this.formFields[index].isLabelHidden)
				this.formFields[index].isLabelHidden = true;
		},
		toggleLabelResetPass(index) {
			const inputElement = document.querySelector(`input[name="${this.formFieldsRP[index].name}"]`);
			const hasText = inputElement.value.trim() !== '';

			if (!hasText)
				this.formFieldsRP[index].isLabelHidden = !this.formFieldsRP[index].isLabelHidden;
			if (hasText && !this.formFieldsRP[index].isLabelHidden)
				this.formFieldsRP[index].isLabelHidden = true;
		},
		login() {
			if (this.formFields[0].value != "" && this.formFields[1].value != "") {
				let login_data = new FormData();
				login_data.append('login', this.formFields[0].value);
				login_data.append('password', this.formFields[1].value);

				axios.post(`${BACK_URL}/api/login`, login_data, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then((response) => {
					if (response.user_data === null) {
						this.$refs.notification.addNotification('Email ou mot de passe incorrect', 'error', 3000);
					} else {
						this.$refs.notification.addNotification('Connexion reussie', 'success', 3000);
						this.updateUserData(response.data.user_data);
						localStorage.me = response.data.user_data.id
						if (localStorage.goToLinkNext != "undefined" && localStorage.goToLinkNext != "")
							this.$router.push({ path: '/', query: { as: localStorage.goToLinkNext }});
						else {
							response.data.user_data.isFirstConnexion ? this.$router.push({ path: '/profile' }) : this.$router.push({ path: '/' });
						}
					}
				})
				.catch((error) => {
					if (error.response.status == 401) {
						this.$refs.notification.addNotification(error.response.data.message, 'error', 3000);
					} else {
						this.$refs.notification.addNotification('Une erreur est survenue lors de la connexion.', 'error', 3000);
					}
				})
			}
		}
	}
}
</script>

<style scoped>
button.orange_button {
    margin: 30px;
}

h1 {
	margin-bottom: 1.25rem;
}

form {
	display: flex;
	flex-direction: column;
	gap: 0.625rem;
}
</style>
