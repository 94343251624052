import axios from 'axios';
import {BACK_URL} from '@/utils/ip'

function user_exists(user_id) {
	if (user_id == "") {
		return (false);
	}
	axios.post(`${BACK_URL}/api/user_exists`, 
		{ userId: user_id }, 
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	).then((response) => {
		return (response.data.exists);
	}).catch(() => {
		return (false);
	});
}

export { user_exists };
