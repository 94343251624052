<template>
	<div>
		<button type="button" class="collapsible" id="skill_collaps">Compétences</button>
		<div class="content">
			<div v-for="(category, category_index) in categories" :key="category_index" class="form-group">
				<div class="side_by_side_container skills">
					<button class="delete_button" @click="removeCategory(category_index)" type="button" v-on:click="this.$emit('edited')" :disabled="this.isReadOnly"></button>
					<h3 class="category_title text">{{ category.name }}</h3>
				</div>
				<div class="side_by_side_container">
					<div class="side_by_side_container">
						<div v-for="(tag, tag_index) in category.tags" :key="tag_index">
							<div class="side_by_side_container tag_container">
								<span class="tag text">{{ tag }}</span>
								<button class="delete_button" @click="removeTag(category_index, tag_index)" type="button" :disabled="this.isReadOnly"></button>
							</div>
						</div>
						<input v-if="isNewTagVisible === category_index" ref="newTagInput" type="text" v-model="newTag" placeholder="Ajouter une compétence" style="width: 180px;" @keyup.enter="addTag(category_index)" :readonly="this.isReadOnly" v-on:input="this.$emit('edited')" v-on:focusout="addTag(category_index)" >
						<button v-if="isNewTagVisible === category_index" class="delete_button" @click="toggleSearch(category_index)" type="button" v-on:click="this.$emit('edited')" :disabled="this.isReadOnly"></button>
						<button v-else class="plus_button" @click="toggleSearch(category_index)" type="button" v-on:click="this.$emit('edited')" :disabled="this.isReadOnly"></button>
					</div>
				</div>
			</div>
			<div class="form-group" style="display: flex;justify-content: center;margin-bottom: 5px;">
				<!--<input ref="newCategoryInput" type="text" v-model="newCategoryName" placeholder="Ajouter une catégorie" @keyup.enter="addCategory" :readonly="this.isReadOnly" v-on:input="this.$emit('edited')">-->
				<div class="select-dropdown" id="select-dropdown">
					<select name="skill_list">
						<option v-for="(skill) in this.skills_options" :value="skill" :key="skill">{{ skill }}</option>
					</select>
				</div>
			</div>
			<button class="orange_button" @click="toggleNewCategoryForm" type="button" v-on:click="this.$emit('edited')" :disabled="this.isReadOnly">Ajouter une catégorie</button>
		</div>
	</div>
</template>

<script>
import { SkillsOptions } from '@/utils/enums/dropdownOptions';
import { mapActions, mapGetters } from 'vuex';

export default {
	data() {
		return {
			categories: [],
			newTag: '',
			newCategoryName: '',
			isNewTagVisible: null,
			isNewCategoryVisible: false,
			skills_options: SkillsOptions
		};
	},
	props: ['isReadOnly'],
	emits: ['edited', 'input', 'click'],
	// eslint-disable-next-line no-unused-vars
	setup(props) {
	},
	computed: {
		...mapGetters({
			skills_data: 'getSkillsData',
			empty_resume: 'getEmptyResume'
		}),
	},
	created() {
		if (this.skills_data) {
			this.skills_data.forEach(element => {
				this.categories.push({ name: element["category"], tags: element["tags"] });
				var idx = this.skills_options.indexOf(element["category"])
				if (idx != -1)
					this.skills_options.splice(idx, 1)
			});
		}
	},
	mounted() {
		var skillContent = document.getElementById("skill_collaps");
		skillContent.addEventListener("click", function () {
			this.classList.toggle("active");
			var content = this.nextElementSibling;
			if (content.style.display === "block") {
				content.style.display = "none";
			} else {
				content.style.display = "block";
			}
		});
		if (this.skills_options.length == 0) {
			document.getElementById("select-dropdown").style.display = "none";
		}
	},
	methods: {
		...mapActions(['updateSkillsFormData']),
		toggleSearch(categoryIndex) {
			this.isNewTagVisible = this.isNewTagVisible === categoryIndex ? null : categoryIndex;
			if (this.isNewTagVisible != null) {
				this.$nextTick(() => {
					this.$refs.newTagInput[0].focus();
				});
			}
		},
		addTag(categoryIndex) {
			if (this.newTag.trim() !== '') {
				this.categories[categoryIndex].tags.push(this.newTag);
				this.newTag = '';
			}
			this.toggleSearch(categoryIndex);
		},
		removeTag(categoryIndex, tagIndex) {
			this.categories[categoryIndex].tags.splice(tagIndex, 1);
		},
		removeCategory(categoryIndex) {
			this.skills_options.push(this.categories.at(categoryIndex).name)
			this.categories.splice(categoryIndex, 1);
		},
		toggleNewCategoryForm() {
			let skill = document.getElementsByName("skill_list")[0].value
			this.categories.push({ name: skill, tags: [] });
			this.skills_options.splice(this.skills_options.indexOf(skill), 1)
			this.addTag(this.categories.length - 1)
		},
		updateData() {
			let new_data = [];

			for (let i = 0; i < this.categories.length; i++) {
				new_data.push({
					category: this.categories[i].name,
					tags: this.categories[i].tags
				})
			}
			if (this.categories.length == 0) {
				new_data.push(this.empty_resume.skills[0])
			}
			this.updateSkillsFormData(new_data);
		}
	},
	watch: {
		categories: {
			deep: true,
			handler() {
				this.updateData();
			}
		},
		skills_options: {
			deep: true,
			handler() {
				if (this.skills_options.length == 0) {
					document.getElementById("select-dropdown").style.display = "none";
				}
				else {
					document.getElementById("select-dropdown").style.display = "block";
				}
			}
		}
	}
};
</script>

<style>
.category_title {
	display: inline-block;
	font-weight: bold;
	margin-bottom: 1.875rem;
	border-bottom: 0.0625rem solid #002339;
	margin-left: 0.625rem;
}

.tag{
	padding-right: 0.625rem;
	text-wrap: nowrap;
}

.tag_container {
	border-bottom: 0.0625rem solid #002339;
	padding: 0.325rem;
	margin-right: 0.325rem;
	
}

.side_by_side_container.skills {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
</style>