<template>
	<PopupNotification ref="notification" />
	<div class="resume-header">
		<div class="name title text" >{{ this.user.firstName }}</div>
		<input type="submit" class="orange_button" style="margin: 5px;height: fit-content;" value="Annuler" @click="cancelChanges" :disabled="this.isReadOnly || !this.isEdited"/>
		<input type="submit" class="orange_button" style="margin: 5px;height: fit-content;" value="Sauvegarder" @click="submitForm" :disabled="this.isReadOnly || !this.isEdited"/>
		<input v-show="!this.isReadOnly" type="submit" class="orange_button" style="margin: 5px;height: fit-content;" :value="this.isEditingElse ? 'Approuver' : 'Valider et envoyer'" @click="isValidateModalVisible = true" :disabled="this.isEdited" />
	</div>
	<form @submit.prevent="">
		<ProfileForm :key="this.forceRefresh" :isReadOnly="this.isReadOnly" @edited="this.sectionHasBeenEditedHandler()"/>
		<EducationForm :key="this.forceRefresh" :isReadOnly="this.isReadOnly" @edited="this.sectionHasBeenEditedHandler()"/>
		<LanguagesForm :key="this.forceRefresh" :isReadOnly="this.isReadOnly" @edited="this.sectionHasBeenEditedHandler()"/>
		<SkillForm :key="this.forceRefresh" :isReadOnly="this.isReadOnly" @edited="this.sectionHasBeenEditedHandler()"/>
		<ExperiencesForm :key="this.forceRefresh" :isReadOnly="this.isReadOnly" @edited="this.sectionHasBeenEditedHandler()" :is-editing-else="this.isEditingElse"/>

		<p class="text" style="margin-top: 40px;"  v-if="this.isEditingElse">Paramètres PDF</p>
		<div class="pdf-settings" v-if="this.isEditingElse">
			
			<section title="Slide 5" class="section_checkbox text">
				<p class="text" style="margin-bottom: 5px;">Page en trop ?</p>
				<div class="slider-checkbox text">
					<input id="remove_page_checkbox" type="checkbox" v-on:input="this.isEdited = true" :checked="this.removePageState" />
					<label for="remove_page_checkbox">
						<span id="ball"></span>
					</label>
				</div>
			</section>
			<!--<div class="text tolerance-section">
				<p class="text" style="margin-bottom: 5px;">Tolérance dépassement de lignes</p>
				<span class="input-wrapper-pdf">
					<button name="decrement" className="tolerance">-</button>
					<input type="number" :value="this.toleranceValue" id="tolerance-value"/>
					<button name="increment" className="tolerance">+</button>
				</span>
			</div>
			<div class="text tolerance-section">
				<p class="text" style="margin-bottom: 5px;">Espacement des lignes de texte</p>
				<span class="input-wrapper-pdf">
					<button name="decrement" className="line-spacing">-</button>
					<input type="number" :value="this.lineSpacingValue" id="line-spacing-value"/>
					<button name="increment" className="line-spacing">+</button>
				</span>
			</div>
			<div class="text tolerance-section">
				<p class="text" style="margin-bottom: 5px;">Espacement des lignes de table</p>
				<span class="input-wrapper-pdf">
					<button name="decrement" className="space-before">-</button>
					<input type="number" :value="this.spaceBeforeValue" id="space-before-value"/>
					<button name="increment" className="space-before">+</button>
				</span>
			</div>-->
		</div>
	</form>
	

	<div v-if="isValidateModalVisible" class="modal">
		<div v-if="!this.isEditingElse" class="modal-content">
			<h2 class="text">Confirmer la validation</h2>
			<p class="text">Vous êtes sur le point de valider votre CV ! Si vous pensez que votre CV est complet vous pouvez alors confirmer la validation.</p>
			<p class="text">Après validation, vous pourrez toujours le consulter en lecture seule</p>
			<div>
				<button @click="validateResume" class="orange_button"  style="background-color: green; color: white; margin-right: 1rem;">VALIDER et ENVOYER</button>
				<button @click="isValidateModalVisible = false" class="cancel-button">Annuler</button>
			</div>
		</div>
		<div v-else class="modal-content">
			<h2 class="text">Confirmer l'approbation</h2>
			<h3 v-if="resumeStatus == 0" class="text" style="color: red; font-size: larger;">Attention: ce CV n'a pas encore été validé par {{ this.user.firstName }} ! Vous pouvez tout de meme l'approuver si vous le souhaitez.</h3>
			<p class="text">Vous êtes sur le point d'approuver le CV de {{ this.user.firstName }} ! Approuver le CV signifie qu'il est prêt à être utilisé et n'a plus pour vocation de changer</p>
			<p class="text">Après approbation, vous pourrez toujours le consulter en lecture seule</p>
			<p class="text">Il sera toujours possible de revenir en arrière</p>
			<div>
				<button @click="validateResume" class="orange_button"  style="background-color: green; color: white; margin-right: 1rem;">APPROUVER</button>
				<button @click="isValidateModalVisible = false" class="cancel-button">Annuler</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ref } from 'vue';
import ProfileForm from '@/components/Generator/ProfileForm.vue';
import EducationForm from '@/components/Generator/EducationForm.vue';
import LanguagesForm from '@/components/Generator/LanguagesForm.vue';
import SkillForm from '@/components/Generator/SkillsForm.vue';
import ExperiencesForm from '@/components/Generator/ExperiencesForm.vue';
import PopupNotification from '@/components/Utils/PopupNotification.vue';
import axios from 'axios';
import {BACK_URL} from '@/utils/ip'
import ResumeStatus from '@/utils/enums/resumeStatus';



export default {
	name: 'ResumeForm',
	components: {
		ProfileForm,
		EducationForm,
		LanguagesForm,
		SkillForm,
		ExperiencesForm,
		PopupNotification,
	},
	data() {
		
		return {
			isValidateModalVisible: false,
			resumeStatus: ref(ResumeStatus.ON_GOING), // Resume status
			isReadOnly: ref(false), // Is the form should be read only ?
			isEditingElse: ref(false), // Are we editing someone else's resume ?

			removePageState: ref(false), // PDF Settings
			toleranceValue: ref(4), // PDF Settings
			lineSpacingValue: ref(10), // PDF Settings
			spaceBeforeValue: ref(10), // PDF Settings


			isEdited: ref(false), // Si le CV a été édité
			forceRefresh: ref(0),
			
		};
		
	},
	computed: {
		...mapGetters({
			resume_data: 'getResumeData',
			user: 'getUserData',
		}),
		
	},
	watch: {
		// Watch for changes in the resume status
		// If it changes for a valid or approved status, we set the form to read only
		resumeStatus: {
			handler() {
				console.log("resumeStat : " + this.resumeStatus)
				if ((this.resumeStatus == ResumeStatus.VALIDATE && this.isEditingElse == false) || this.resumeStatus == ResumeStatus.APPROVED) {
					this.isReadOnly = true
				}
			}
		},
		isEditingElse: {
			handler() {
				if ((this.resumeStatus == ResumeStatus.ON_GOING && this.isEditingElse == false) || this.resumeStatus == ResumeStatus.APPROVED)
					this.isReadOnly = true
			}
		},
	},
	methods: {
		...mapActions(['updateResumeFormData']),
		forceRerender() {
			this.forceRefresh += 1;
		},
		/**
		* Fetch the datas from the API
		* If the user is editing someone else's resume, we fetch the datas from the user id in the query
		*/
		async fetchDatas() {
			await axios.get(`${BACK_URL}/api/data`, { params: { userId: this.isEditingElse != "" ? this.$route.query.as : this.user.id }})
			.then(async (response) => {
				// If the response is not empty, we update the store with the datas
				if (response.status != 204) {
					try {
						await this.updateResumeFormData(response.data.data)
					} catch (error) {
						console.error(error)
					}
				}
				else
					await this.updateResumeFormData(this.empty_resume) // Else we update the store with an empty resume
				// Then we force the rerender of the components
				this.forceRerender()
			})
			.catch((error) => {
				try {
					console.error('Error fetching data:', error)
					this.$refs.notification.addNotification("Erreur lors de la récupération des données.", "error", 3000);
				} catch {
					console.error("NotificationError")
				}
				
			});

			
		},
		/**
		 * Cancel the changes made to the form
		 */
		async cancelChanges() {
			await this.fetchDatas();
			this.isEdited = false
		},
		/**
		 * Submit the form to the API
		 * Called by "Sauvegarder" button
		 */
		async submitForm() {
			let form_data = {
				userId: this.isEditingElse == true ? this.$route.query.as : this.user.id,
				role: this.user.role,
				pdfSettings: {
					removePage: this.removePageState,
					tolerance: this.toleranceValue,
					lineSpacing: this.lineSpacingValue,
					spaceBefore: this.spaceBeforeValue
				},
				data: {
					profile: this.resume_data.profile,
					education: this.resume_data.education,
					languages: this.resume_data.languages,
					skills: this.resume_data.skills,
					experience: this.resume_data.experience
				}
			};
			axios.post(`${BACK_URL}/api/data`, form_data).then(() => {
				this.$refs.notification.addNotification("Sauvegarde réussie.", "success", 3000);
				this.isEdited = false
			})
			.catch((error) => {
				console.error('Error submitting form:', error);
				this.$refs.notification.addNotification("Une erreur est survenue lors de la sauvegarde.", "error", 3000);
			})
			
		},
		/**
		 * Validate the resume
		 * Called by "Valider et envoyer" button and "Approuver" button
		 */
		async validateResume() {
			let payload = {
				isEditingElse: this.isEditingElse,
				userId: this.isEditingElse == true ? this.$route.query.as : this.user.id,
			}
			
			// First we save the datas
			let form_data = {
				userId: this.isEditingElse == true ? this.$route.query.as : this.user.id,
				role: this.user.role,
				data: {
					profile: this.resume_data.profile,
					education: this.resume_data.education,
					languages: this.resume_data.languages,
					skills: this.resume_data.skills,
					experience: this.resume_data.experience
				}
			};
			axios.post(`${BACK_URL}/api/data`, form_data).then(() => {
				// Then we validate the resume
				this.isEdited = false
				axios.post(`${BACK_URL}/api/validate_resume`, payload)
				.then((response) => {
					if (response.status == 200) {
						this.resumeStatus = response.data
						this.isValidateModalVisible = false
						this.$refs.notification.addNotification("Le CV à bien été validé !", "success", 3000);
					}
				})
				.catch((error) => {
					this.$refs.notification.addNotification(error.response.data.message, "error", 3000);
				})
			})
			.catch((error) => {
				console.error('Error submitting form:', error);
				this.$refs.notification.addNotification("Une erreur est survenue lors de la sauvegarde.", "error", 3000);
			})
		},
		/**
		 * Get the resume status
		 */
		async getResumeStatus() {
			axios.post(`${BACK_URL}/api/resume_status`, {"userId": this.isEditingElse ? this.$route.query.as : this.user.id}, {headers: {'Content-Type': 'application/json'}})
			.then((response) => {
				this.resumeStatus = response.data.resumeStatus
				this.isDatasLoaded = true
			})
			.catch(() => {
				console.log("Can't get resume status")
			})
		},
		/**
		 * Get the PDF settings
		 */
		async getPdfSettings() {
			await axios.post(`${BACK_URL}/api/get_pdf_settings`, {"userId": this.isEditingElse ? this.$route.query.as : this.user.id}, {headers: {'Content-Type': 'application/json'}})
			.then((response) => {
				
				const pdfSettings = response.data.pdfSettings
				
				// If the response is not empty, we update the store with the datas, else we keep the default values
				if (pdfSettings != null) {
					this.removePageState = pdfSettings.removePage !== null ? pdfSettings.removePage : false
					this.toleranceValue = pdfSettings.tolerance !== null ? pdfSettings.tolerance : 4
					this.lineSpacingValue = pdfSettings.lineSpacing !== null ? pdfSettings.lineSpacing : 10
					this.spaceBeforeValue = pdfSettings.spaceBefore !== null ? pdfSettings.spaceBefore : 10
				}
				
				const incrementButtons = document.getElementsByName("increment");
				const decrementButtons = document.getElementsByName("decrement");
				
				// Add event listeners to the increment and decrement buttons
				incrementButtons.forEach((element) => {
					element.addEventListener('click', (e) => {
						// Increment the right value of the input based on the class of the button
						switch (e.srcElement.className) {
							case "tolerance":
								this.toleranceValue = (parseInt(this.toleranceValue) + 1).toString();
								break;
							case "line-spacing":
								this.lineSpacingValue = (parseInt(this.lineSpacingValue) + 1).toString();
								break;
							case "space-before":
								this.spaceBeforeValue = (parseInt(this.spaceBeforeValue) + 1).toString();
								break;
						}
						this.isEdited = true
					});
				});
				decrementButtons.forEach((element) => {
					element.addEventListener('click', (e) => {
						switch (e.srcElement.className) {
							case "tolerance":
								this.toleranceValue -= 1;
								break;
							case "line-spacing":
								this.lineSpacingValue -= 1;
								if (this.lineSpacingValue < 10)
									this.lineSpacingValue = 10
								break;
							case "space-before":
								this.spaceBeforeValue -= 1;
								break;
						}
						this.isEdited = true
					});
				});
			})
			.catch((e) => {
				console.log("Error : " + e)
			})
		},
		// Event handler for the edited event
		sectionHasBeenEditedHandler() {
			this.isEdited = true
		}
	},
	async beforeMount() {
		// If the user is not logged in, we redirect him to the login page
		if (this.user.id == "")
			this.$router.push({ name: 'login' });
		
		return
	},
	/* fonction apellée au moment de l'affichage de la page, ajoute un évènement "click" sur les collpasibles qui permet de déployer la section en dessous	*/
	async mounted() {
		this.isEditingElse = this.$route.query.as != undefined
		this.getResumeStatus();
		this.getPdfSettings();
		await this.fetchDatas()

		

		await axios.get(`${BACK_URL}/api/user/${this.isEditingElse != "" ? this.$route.query.as : this.user.id}`)
		.then(response => {
			this.user.firstName = response.data.firstName
			this.user.role = response.data.role
			this.user.isEditingElse = this.isEditingElse
		})
		.catch((error) => {
			console.error('Error fetching data:', error)
			try {
				this.$refs.notification.addNotification("Erreur lors de la récupération des données.", "error", 3000);
			} catch {
				console.log()
			}
		});
	}
};
</script>

<style>
/* section déroulante */
.collapsible {
	background-color: #fcfcfc;
	color: #002339;
	cursor: pointer;
	padding: 1.125rem;
	width: 100%;
	border: #002339 0px;
	text-align: left;
	outline: none;
	font-size: 0.75rem;
	text-transform: uppercase;
	border-bottom: 0.125rem solid #f9a825;
	margin-top: 0.3125rem;
}

/* ajoute un + sur la droite du collapsible*/
.collapsible:after {
	content: '\002B';
	color: #002339;
	float: right;
	margin-left: 0.3125rem;
}

.content {
	animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) alternate both;
}

@keyframes swing-in-top-fwd {
  0% {
	-webkit-transform: rotateX(-100deg);
			transform: rotateX(-100deg);
	-webkit-transform-origin: top;
			transform-origin: top;
	opacity: 0;
  }
  100% {
	-webkit-transform: rotateX(0deg);
			transform: rotateX(0deg);
	-webkit-transform-origin: top;
			transform-origin: top;
	opacity: 1;
  }
}

/* ajoute un - sur la droite du collapsible activé */
.active:after {
	content: "\2212";
}

/* change la couleur du collapsible activé */
.active,
.collapsible:hover {
	background-color: #f5f5f5;
}

/* style du contenu du collapsible */
.content {
	padding: 1.125rem;
	display: none;
	overflow: hidden;
	background-color: #f5f5f5;
}

.form-group {
	margin-bottom: 2.1875rem;
	animation: tracking-in-expand 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@keyframes tracking-in-expand {
  0% {
	letter-spacing: -0.5em;
	opacity: 0;
  }
  40% {
	opacity: 0.6;
  }
  100% {
	opacity: 1;
  }
}

.delete_button,
.plus_button {
	border-radius: 50%;
	border-width: 0px;
	color: white;
	width: 0.93rem;
	height: 0.93rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.plus_button {
	background-color: #f9a825;
	background-image: url('@/assets/images/add.svg');
	background-repeat: no-repeat;
	background-size: contain;
}

.delete_button {
	background-color: #002339;
	background-image: url('@/assets/images/close.svg');
	background-repeat: no-repeat;
	background-size: contain;
	float: right;
	cursor: pointer;
}

.delete_button:disabled,
.plus_button:disabled {
	visibility: hidden;
	cursor: default;
}

/* affiche les éléments cote à cote */
.side_by_side_container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.name {
	text-align: center !important;
	font-size: 25px !important;
	position: sticky;
	top: -50px;
	background: white;
	z-index: 2;
	padding: 25px !important;
	width: 93% !important;
	text-decoration-line: underline;
	text-decoration-color: #f9a825;
}

.resume-header {
	display: flex;
	position: sticky;
	top: -40px;
	background: white;
	z-index: 2;
	align-items: center;
}

.orange_button:disabled {
	background-color: grey;
	animation: none;
	cursor: default;
}

.orange_button:disabled:hover {
	color: white;
	animation: none;
}

.input-wrapper-pdf {
    width: 150px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    margin: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
	padding: 10px;
	text-align: center;
}

.input-wrapper-pdf * {
	border: none;
	width: 50px;
	flex: 1;
}

.input-wrapper-pdf button {
    cursor: pointer;
    background-color: grey;
}

.input-wrapper-pdf button:hover {
	transition: all 0.3s ease;
    cursor: pointer;
    background-color: #f9a927;
}

.input-wrapper-pdf button:first-child {
	border-radius: 10px 0 0 10px;
	color: white;
}

.input-wrapper-pdf button:last-child {
	border-radius: 0 10px 10px 0;
	color: white
}

.pdf-settings {
    display: grid;
    align-items: center;
    justify-content: space-evenly;
    justify-items: center;
    /*grid-template: repeat(2, 1fr) / repeat(2, 1fr);*/
}

.text.tolerance-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

</style>
