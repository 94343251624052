
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
	plugins: [createPersistedState({
		storage: window.sessionStorage,
	})],
	state : {
		user_data: {
				id: ''
		},
		resume_data: {
			// informations personnelles
			profile: {
				firstName: '',
				everwinNum: '',
				position: ''
			},
			// liste des diplômes
			education: [{
				year: '',
				school: '',
				degree: ''
			}],
			// liste des langues
			languages: [{
				language: '',
				level: ''
			}],
			// liste des compétences
			skills: [{
				category: '',
				tags: []
			}],
			// liste des expériences professionnelles
			experience: [{
				company: '',
				position: '',
				location: '',
				start_date: '',
				end_date: '',
				current_job: false,
				description: '',
				objectives: [],
				tasks: [],
				environments: [],
				page_break: false
			}],
		},
		empty_resume: {
			// informations personnelles
			profile: {
				firstName: '',
				everwinNum: '',
				position: ''
			},
			// liste des diplômes
			education: [{
				year: '',
				school: '',
				degree: ''
			}],
			// liste des langues
			languages: [{
				language: '',
				level: ''
			}],
			// liste des compétences
			/*{
				category: '',
				tags: []
			}*/
			skills: [],
			// liste des expériences professionnelles
			experience: [{
				company: '',
				position: '',
				location: '',
				start_date: '',
				end_date: '',
				current_job: false,
				description: '',
				objectives: [],
				tasks: [],
				environments: []
			}],
		}
	},
	mutations: {
		// mutations pour l'utilisateur
		setUserData(state, newValue) {
			state.user_data = newValue;
		},

		// mutations pour le formulaire de CV
		updateResumeFormData(state, newValue) {
			state.resume_data = newValue;
		},
		updateProfileFormData(state, newValue) {
			state.resume_data.profile = newValue;
		},
		updateEducationFormData(state, newValue) {
			state.resume_data.education = newValue;
		},
		updateLanguagesFormData(state, newValue) {
			state.resume_data.languages = newValue;
		},
		updateSkillsFormData(state, newValue) {
			state.resume_data.skills = newValue;
		},
		updateExperienceFormData(state, newValue) {
			state.resume_data.experience = newValue;
		}
	},
	actions: {
		// actions pour l'utilisateur
		updateUserData({ commit }, newValue) {
			commit('setUserData', newValue);
		},
		
		// actions pour le formulaire de CV
		updateResumeFormData({ commit }, newValue) {
			commit('updateResumeFormData', newValue);
		},
		updateProfileFormData({ commit }, newValue) {
			commit('updateProfileFormData', newValue);
		},
		updateEducationFormData({ commit }, newValue) {
			commit('updateEducationFormData', newValue);
		},
		updateLanguagesFormData({ commit }, newValue) {
			commit('updateLanguagesFormData', newValue);
		},
		updateSkillsFormData({ commit }, newValue) {
			commit('updateSkillsFormData', newValue);
		},
		updateExperienceFormData({ commit }, newValue) {
			commit('updateExperienceFormData', newValue);
		}
	},
	getters: {
		// getters pour l'utilisateur
		getUserData: state => state.user_data,

		getEmptyResume: state => state.empty_resume,
		
		// getters pour le formulaire de CV
		getResumeData: state => state.resume_data,
		getProfileData: state => state.resume_data.profile,
		getEducationData: state => state.resume_data.education,
		getLanguagesData: state => state.resume_data.languages,
		getSkillsData: state => state.resume_data.skills,
		getExperienceData: state => state.resume_data.experience
	}
});
